<template>
  <div class="d-flex flex-column align-center">
    <iframe
      v-if="iframeUrl"
      :src="iframeUrl"
      class="bridger-iframe"
      frameborder="0"
      allow="payment"
    ></iframe>
    <form v-else id="payment-form" class="w-100">
      <label>Card Holder Name</label>
      <div class="mb-4">
        <input
          id="cardholder-name"
          class="field w-100"
          placeholder="John Doe"
        />
      </div>

      <label>Email</label>
      <div class="mb-4">
        <input
          id="email"
          class="field w-100"
          placeholder="john.doe@example.com"
        />
      </div>

      <label>Phone</label>
      <div class="mb-4">
        <phone-input
          :value.sync="phone"
          :error.sync="showPhoneError"
          :initial-phone="initialPhone"
        />
      </div>
      <template v-if="requireAddressFields">
        <label>Address</label>
        <div class="mb-4">
          <input
            id="address1"
            v-model="address1"
            class="field w-100"
            placeholder="123 Main St"
          />
        </div>

        <div class="field-set d-flex gap-3">
          <div class="flex-1">
            <label>City</label>
            <div class="mb-4">
              <input
                id="city"
                v-model="city"
                class="field w-100"
                placeholder="New York"
              />
            </div>
          </div>

          <div class="flex-1">
            <label>ZIP Code</label>
            <div class="mb-4">
              <input
                id="zip_code"
                v-model="zipCode"
                class="field w-100"
                placeholder="10001"
              />
            </div>
          </div>
        </div>
      </template>

      <label>Card Number</label>
      <div id="card-number" class="field mb-4">
        <div class="blox-input"></div>
      </div>

      <div class="field-set d-flex gap-3">
        <div class="flex-1">
          <label>Expiration Date</label>
          <div id="exp-date" class="field">
            <div class="input"></div>
          </div>
        </div>

        <div class="flex-1">
          <label>CVV</label>
          <div id="cvv" class="field w-100">
            <div class="input"></div>
          </div>
        </div>
      </div>
      <div v-if="formError" class="error--text text-caption mt-1">
        {{ formError }}
      </div>

      <v-btn
        class="mt-8"
        type="submit"
        block
        depressed
        color="success"
        :loading="processing"
        >Confirm & Pay</v-btn
      >
    </form>
  </div>
</template>

<script>
import { loadScript } from '@/utils/script-loader'
import PhoneInput from '@/components/common/phone-input.vue'
import axios from 'axios'

export default {
  name: 'BridgerBlox',
  components: {
    PhoneInput,
  },

  props: {
    sessionToken: {
      type: String,
      required: true,
    },
    intentId: {
      type: String,
      required: true,
    },
    initialName: {
      type: String,
      default: '',
    },
    initialEmail: {
      type: String,
      default: '',
    },
    initialPhone: {
      type: String,
      default: '',
    },
    requireAddressFields: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cardHolderName: '',
      email: '',
      processing: false,
      errorMessage: '',
      showRetry: false,
      hidePayment: false,
      bloxController: null,
      isValid: false,
      phone: '',
      showPhoneError: false,
      formError: null,
      iframeUrl: null,
      validationData: null,
      encrypted: null,
    }
  },

  async mounted() {
    await this.initializeBlox()
    document.getElementById('cardholder-name').value = this.initialName
    document.getElementById('email').value = this.initialEmail

    // Add iframe event listeners
    window.addEventListener('message', this.handlePaymentIframeEvent)

    document
      .getElementById('payment-form')
      .addEventListener('submit', async event => {
        event.preventDefault()
        this.formError = null

        if (this.bloxController.isAllValid === false) {
          this.bloxController.validateAll()
          return
        }
        this.cardHolderName = document.getElementById('cardholder-name').value
        this.email = document.getElementById('email').value
        if (!this.cardHolderName)
          return (this.formError = 'Please enter a Card Holder Name')
        if (!this.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email))
          return (this.formError = 'Please enter a valid email address')
        if (!this.phone.isValid)
          return (this.formError = 'Phone enter a valid phone number')

        try {
          this.processing = true
          this.encrypted = await this.bloxController.createPaymentCardToken({
            cardHolderName: document.getElementById('cardholder-name').value, // This field is mandatory
            singleUse: false,
          })
          this.collectFingerprint()
        } catch (error) {
          console.error(error)
        }
      })
  },

  beforeDestroy() {
    if (this.bloxController) {
      this.bloxController.clearAll()
    }
    window.removeEventListener('message', this.handlePaymentIframeEvent)
  },

  methods: {
    collectFingerprint() {
      axios
        .get('/api/bridgerpay/fingerprint', {
          params: {
            card_bin: this.encrypted.card.bin,
            payment_intent_id: this.intentId,
          },
        })
        .then(response => {
          const fingerprintIframe = document.createElement('iframe')
          fingerprintIframe.setAttribute('style', 'display: none;')
          fingerprintIframe.srcdoc = response.data
          document.body.prepend(fingerprintIframe)
        })
    },
    createPurchase(fingerprint) {
      axios
        .post('/api/bridgerpay/purchase', {
          token: this.encrypted.token,
          encrypted_cvv: this.encrypted.encryptedCvv,
          payment_intent_id: this.intentId,
          full_name: this.cardHolderName,
          email: this.email,
          phone: this.phone.formattedNumber,
          address1: this.address1,
          city: this.city,
          zip_code: this.zipCode,
          fingerprint,
        })
        .catch(error => {
          this.formError = error?.response?.data?.error
          this.processing = false
        })
        .then(response => {
          if (response.data.url) {
            this.iframeUrl = response.data.url
            this.validationData = response.data
          } else {
            this.$emit('payment-success', {
              user: { email: this.email },
            })
          }
        })
    },

    handlePaymentIframeEvent(event) {
      if (event.data.event == '[bp]:fingerprint') {
        this.createPurchase(event.data.fingerprint)
      }
      if (event.data.type === 'approved' && this.iframeUrl) {
        this.iframeUrl = null
        this.$emit('payment-processing')
        axios
          .post('/api/bridgerpay/capture', {
            payment_intent_id: this.intentId,
          })
          .then(() => {
            this.$emit('payment-success', {
              user: { email: this.email },
            })
          })
      }
    },
    async initializeBlox() {
      try {
        await loadScript('https://checkout.bridgerpay.com/sdk')
        this.bloxController = new window.BP.BloxController({
          token: this.sessionToken,
          style: {
            base: {
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '14px',
            },
            invalid: {
              color: '#ff6666',
            },
          },
        })

        const cardNumber = this.bloxController.createBlox('cardNumber', {
          placeholder: this.$t('Card Number'),
        })

        const cardExpiryDate = this.bloxController.createBlox(
          'cardExpiryDate',
          { placeholder: 'MM/YY' }
        )

        const cardCvv = this.bloxController.createBlox('cardCvv', {
          placeholder: this.$t('CVV'),
        })
        await Promise.all([
          cardNumber.mount('#card-number'),
          cardExpiryDate.mount('#exp-date'),
          cardCvv.mount('#cvv'),
        ])

        this.setupValidationListeners(cardNumber, cardExpiryDate, cardCvv)
      } catch (error) {
        console.error('Failed to initialize Blox:', error)
      }
    },

    setupValidationListeners(cardNumber, cardExpiryDate, cardCvv) {
      const validateForm = ({ error }) => {
        if (error) this.formError = error
      }

      cardNumber.on('validate', validateForm)
      cardExpiryDate.on('validate', validateForm)
      cardCvv.on('validate', validateForm)
    },
  },
}
</script>

<style scoped>
.field {
  border-color: var(--v-secondary-base);
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  padding: 10px;
  font-size: 14px;
}
.w-100 {
  width: 100%;
}
.flex-1 {
  flex: 1;
}
.gap-3 {
  grid-gap: 7px;
}
.bridger-iframe {
  width: 100%;
  height: 600px;
  border: none;
  border-radius: 4px;
}
</style>

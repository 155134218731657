<template>
  <v-expansion-panels class="my-3">
    <v-expansion-panel v-if="!touristData.need_pre_checkin">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div><v-icon class="px-3"> $video </v-icon>{{ $t('Guidebook') }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-expansion-panels flat>
          <v-expansion-panel v-for="(category, key) in categories" :key="key">
            <v-expansion-panel-header
              class="px-0 font-weight-medium text-body-2"
              expand-icon="mdi-menu-down"
              >{{ category.title }}</v-expansion-panel-header
            >
            <v-expansion-panel-content class="sections-panel pe-4">
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="(section, sectionKey) in category.sections"
                  :key="sectionKey"
                >
                  <v-expansion-panel-header
                    class="px-0 font-weight-medium text-body-2"
                    expand-icon="mdi-menu-down"
                  >
                    <span>
                      <span
                        v-for="(header, i) in section.headers"
                        :key="header"
                        class="text-caption text-capitalize mt-2 pb-2 font-weight-medium"
                      >
                        <span> {{ header }}</span>
                        <v-icon v-if="section.headers.length - 1 !== i" small
                          >mdi-chevron-right</v-icon
                        >
                      </span></span
                    ></v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="inner-panel">
                    <v-row>
                      <v-col cols="12" class="overflow-hidden pt-0">
                        <div
                          style="white-space: pre-line"
                          class="text-caption mt-1"
                        >
                          <div v-html="marked(section.comments)"></div>
                        </div>
                        <gallery
                          button-height="350px"
                          :thumbnails="true"
                          :cols="12"
                          :images="section.images"
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                  <v-divider />
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <div v-else class="text-center error--text text-body-3 text-sm-body-1">
      Please complete the Pre Check-in process
    </div>
    <v-expansion-panel v-if="hasDoorCodes" :disabled="disabled">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div>
          <v-icon class="px-3"> $door_key </v-icon>{{ $t('DoorCodes') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-if="isAlert(doorCodes)"
          class="d-flex align-center pa-2 error--text"
        >
          <v-icon size="20" color="error" class="mr-2">
            fas fa-exclamation-triangle
          </v-icon>
          <span class="text-body-2">{{ doorCodes.Alert }}</span>
        </div>

        <v-row>
          <v-col
            v-for="(val, key) in filteredDoorCodes"
            :key="key"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card outlined class="pa-3">
              <div class="text-subtitle-2 text-capitalize grey--text">
                {{ formatLocation(key) }}
              </div>
              <div class="text-h6 font-weight-bold primary--text mt-1">
                <template v-if="isUrl(val)">
                  <v-btn
                    color="primary"
                    :href="val"
                    target="_blank"
                    block
                    outlined
                    small
                    class="text-none text-wrap"
                  >
                    {{ $t('View') }} {{ formatLocation(key) }}
                  </v-btn>
                </template>
                <template v-else>
                  <div class="text-break text-body-2">{{ val }}</div>
                </template>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="hasWifi">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div><v-icon class="px-3"> $wifi </v-icon>{{ $t('Wifi') }}</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(val, key) in touristData.info.wifi" :key="key">
          <div class="d-flex align-center">
            <v-icon v-if="key === 'Alert'" size="20" color="error">
              fas fa-exclamation-triangle
            </v-icon>
            <span v-else class="text-body-1 text-capitalize"
              >{{ $t(key) }}:</span
            >
            <span
              :class="[
                'body-2',
                'ms-2',
                key === 'Alert' ? 'error--text' : 'bolder',
              ]"
            >
              {{ val }}</span
            >
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="hasContactInfo">
      <v-expansion-panel-header class="pa-3" expand-icon="mdi-menu-down">
        <div>
          <v-icon class="px-3"> $phone_call </v-icon>{{ $t('Contact Us') }}
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          class="d-grid mt-2"
          :style="`grid-gap: ${isMobile ? '1rem' : '3rem'};
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(50px, 1fr))`"
        >
          <div
            v-for="(phones, key) in touristData.info.phones"
            :key="key"
            class="d-flex flex-column justify-center align-center text-center"
          >
            <div class="me-2">
              <v-icon v-if="key === 'Alert'" size="20">
                fas fa-exclamation-triangle
              </v-icon>
            </div>
            <div
              v-for="phone in phones"
              :key="phone"
              :class="[
                'body-2',
                key === 'Alert' ? 'error--text' : 'bolder',
                'text-center',
              ]"
            >
              <div v-if="key == 'Whatsapp'">
                <a target="_blank" :href="`https://wa.me/${phone}`"
                  ><v-icon color="green" large size="30">mdi-whatsapp</v-icon>
                </a>
              </div>
              <div v-else-if="key == 'SMS'">
                <a target="_blank" :href="`sms:${phone}`"
                  ><v-icon color="blue" size="30">mdi-message-text</v-icon></a
                >
              </div>
              <div v-else-if="key == 'Phone'">
                <a target="_blank" :href="`tel:${phone}`"
                  ><v-icon color="teal" size="30">mdi-phone</v-icon></a
                >
              </div>
              <div v-else>{{ phone }}</div>
            </div>
            <div
              v-if="!isMobile"
              class="text-caption secondary--text mt-1 text-no-wrap"
            >
              {{ phones[0] }}
            </div>
          </div>

          <div
            v-for="(link, key) in links"
            :key="key"
            style="text-align: center"
          >
            <a target="_blank" :href="link">
              <v-icon :color="iconMap[key].color" size="30">{{
                iconMap[key].icon
              }}</v-icon>
            </a>
            <div
              v-if="!isMobile"
              class="text-caption secondary--text mt-1 text-no-wrap"
            >
              {{ iconMap[key].name }}
            </div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import filter from 'lodash/fp/filter'
import Gallery from 'components/common/gallery.vue'
import deviceMixin from 'components/mixins/deviceMixin'
import { groupBy, orderBy } from 'lodash'
import { marked } from 'marked'

const renderer = new marked.Renderer()
renderer.link = function (href, title, text) {
  return `<a href="${href}" title="${title}" target="_blank" rel="noopener noreferrer">${text}</a>`
}
export default {
  name: 'BookingCodes',
  components: { Gallery },
  mixins: [deviceMixin],
  props: ['touristData', 'disabled'],
  data() {
    return {
      iconMap: {
        facebook: { icon: 'mdi-facebook', color: 'blue', name: 'Facebook' },
        google: { icon: 'mdi-google', color: 'red', name: 'Google' },
        linkedin: { icon: 'mdi-linkedin', color: 'blue', name: 'LinkedIn' },
        website: { icon: 'mdi-web', color: 'green', name: 'Website' },
        booking_site: {
          icon: 'mdi-web-box',
          color: 'blue',
          name: 'Booking Site',
        },
        instagram: {
          icon: 'mdi-instagram',
          color: 'purple',
          name: 'Instagram',
        },
      },
    }
  },
  computed: {
    doorCodes() {
      return this.touristData.info['door_codes']
    },
    filteredDoorCodes() {
      if (!this.doorCodes) return {}
      const codes = { ...this.doorCodes }
      delete codes.Alert
      return codes
    },
    links() {
      return this.touristData.info.links || []
    },
    hasDoorCodes() {
      return filter(Boolean, this.doorCodes).length !== 0
    },
    hasContactInfo() {
      return (
        (this.touristData.info.phones &&
          Object.keys(this.touristData.info.phones).length > 0) ||
        this.links.length > 0
      )
    },
    hasWifi() {
      return (
        this.touristData.info.wifi &&
        Object.keys(this.touristData.info.wifi).length > 0
      )
    },
    categories() {
      const filteredGuestsInfo = this.touristData.guests_info.filter(
        info => info.listing_info_def_id
      )
      const categories = groupBy(filteredGuestsInfo, 'listing_info_def.topic')
      const grouped = Object.keys(categories).map(key => ({
        title: key,
        sections: this.sections(categories[key]),
      }))
      return orderBy(grouped, 'title')
    },
  },
  methods: {
    marked(val) {
      return val ? marked(val, { renderer: renderer }) : val
    },
    sections(categoryInfo) {
      const orderedCategories = orderBy(
        categoryInfo,
        'listing_info_def.sub_topic'
      )
      return (orderedCategories || []).map(info => {
        const { listing_info_def, comments, images } = info
        const { sub_topic, hint } = listing_info_def
        return {
          headers: [sub_topic, hint].filter(Boolean),
          comments: comments,
          images: images,
        }
      })
    },
    isAlert(codes) {
      return codes && 'Alert' in codes
    },
    formatLocation(key) {
      return key.replace(/_/g, ' ')
    },
    isUrl(value) {
      return typeof value === 'string' && value.toLowerCase().includes('http')
    },
  },
}
</script>

<style scoped>
.icon-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.theme--light.v-icon {
  color: inherit;
}
.inner-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
.sections-panel >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
.text-break {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>

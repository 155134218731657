<template>
  <v-layout column>
    <v-form
      ref="form"
      v-model="valid"
      class="pa-0 flex checkout-form"
      lazy-validation
      @submit.prevent="submitPaymentForm"
    >
      <v-layout column justify-space-between>
        <slot />
        <div class="text-h5 font-weight-medium black--text mb-4">
          Personal Details
        </div>
        <v-flex class="tokenizer-form">
          <v-text-field
            v-model="fullname"
            label="Full Name"
            placeholder="John Doe"
            outlined
            dense
            :rules="nameRules"
            required
          />
          <v-text-field
            v-model="email"
            label="Email"
            placeholder="john@example.com"
            outlined
            dense
            :rules="emailRules"
            required
          />
          <v-text-field
            v-model="phone"
            label="Phone"
            placeholder="(123) 456-7890"
            outlined
            dense
            :rules="phoneRules"
            required
          />
          <v-text-field
            v-model="zipCode"
            :label="$t('Zip code')"
            :rules="zipCodeRules"
            outlined
            dense
            placeholder="12345"
            required
          />
          <div class="text-h5 font-weight-medium black--text mb-4">
            Card Details
          </div>
          <v-row class="d-flex mb-4">
            <v-col cols="12" sm="6" md="12">
              <div id="cardnum" class="checkout_form"></div>
              <div
                v-if="errors.cardNumber"
                class="text-caption error--text ml-2"
              >
                {{ errors.cardNumber }}
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <div id="pincode" class="checkout_form"></div>
              <div v-if="errors.cardCvc" class="text-caption error--text ml-2">
                {{ errors.cardCvc }}
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <div id="expiration" class="checkout_form"></div>
              <div
                v-if="errors.cardExpiry"
                class="text-caption error--text ml-2"
              >
                {{ errors.cardExpiry }}
              </div>
            </v-col>
          </v-row>
          <div class="text-caption error--text">
            {{ cardErrors }}
          </div>
          <v-btn
            type="submit"
            :loading="paymentProcessing"
            color="primary"
            elevation="0"
            block
            large
            :disabled="!valid"
          >
            {{ buttonText || 'Pay Now' }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-layout>
</template>

<script>
import FormRulesMixin from '@/components/mixins/form-rules-mixin'
import { BasisTheory } from '@basis-theory/basis-theory-js'
import axios from 'axios'

export default {
  name: 'Tokenizer',
  mixins: [FormRulesMixin],
  props: {
    buttonText: {
      type: String,
      default: 'Pay Now',
    },
    paymentIntentId: {
      type: String,
      required: true,
    },
    reservation: {
      type: Object,
      required: true,
    },
    btPublicApiKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      btPublic: null,
      cardTextElement: '',
      pincodeTextElement: '',
      expirationTextElement: '',
      errors: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
      },
      cardErrors: '',
      valid: false,
      fullname: '',
      phone: '',
      zipCode: '',
      email: '',
      paymentProcessing: false,
    }
  },

  async mounted() {
    if (this.reservation.guest) {
      this.fullname = this.reservation.guest.full_name || ''
      this.phone =
        this.reservation.guest.primary_phone ||
        this.reservation.guest.phones[0] ||
        ''
      this.email =
        this.reservation.guest.primary_email ||
        this.reservation.guest.emails[0] ||
        ''
    }

    this.btPublic = await new BasisTheory().init(this.btPublicApiKey, {
      elements: true,
    })
    this.initiateCardFields()
    await this.btPublic.sessions.create()
  },

  methods: {
    async submitPaymentForm() {
      if (!this.$refs.form.validate()) return

      this.paymentProcessing = true
      this.$emit('payment-processing')

      try {
        const token = await this.btPublic.tokens.create({
          type: 'token',
          data: {
            card: this.cardTextElement,
            pincode: this.pincodeTextElement,
            expiration: this.expirationTextElement,
          },
        })
        axios
          .post('/api/tokenizer/capture', {
            payment_intent_id: this.paymentIntentId,
            token: token.id,
          })
          .then(() => {
            this.$emit('payment-success', {
              token: token.id,
              user: { email: this.email },
            })
          })
      } catch (error) {
        this.cardErrors = error.message
        this.$emit('payment-failed')
      } finally {
        this.paymentProcessing = false
      }
    },

    initiateCardFields() {
      this.cardTextElement = this.btPublic.createElement('cardNumber', {
        targetId: 'cardnum',
        placeholder: '1234 1234 1234 1234',
      })

      this.pincodeTextElement = this.btPublic.createElement(
        'cardVerificationCode',
        {
          targetId: 'pincode',
          placeholder: 'CVC',
        }
      )

      this.expirationTextElement = this.btPublic.createElement(
        'cardExpirationDate',
        {
          targetId: 'expiration',
          placeholder: 'MM/YY',
        }
      )

      this.cardTextElement.mount('#cardnum')
      this.pincodeTextElement.mount('#pincode')
      this.expirationTextElement.mount('#expiration')
    },
  },
}
</script>

<style lang="scss" scoped>
.tokenizer-form {
  .checkout_form {
    padding: 10px 12px 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    width: 100%;
    margin-top: 6px;
    height: 40px;
  }

  .field {
    &:focus {
      border-color: var(--v-primary-base);
      border-width: 2px;
    }

    &.invalid {
      border-color: var(--v-error-base);
      color: var(--v-error-base);
      border-width: 2px;
    }
  }
}
</style>

import moment from 'moment-timezone'
import { isEmpty, get } from 'lodash/fp'
const CommonFunctions = {
  data: function () {
    return {
      myLoader: 0,
    }
  },
  computed: {
    acceptDeposit() {
      const denyChannels = isEmpty(
        this.reservation.checkin_info?.deposit_deny_channels
      )
        ? ['Airbnb', 'flipkey.com', 'TripAdvisor', 'PlumGuide']
        : this.reservation.checkin_info.deposit_deny_channels
      return (
        this.reservation.checkin_info &&
        this.reservation.checkin_info.accept_deposit_payments &&
        !denyChannels.includes(this.reservation.source)
      )
    },
    shouldPayDeposit() {
      return (
        !this.reservation.pre_check_in?.unlock_portal_access &&
        this.acceptDeposit &&
        this.$store.state.tourist.shouldPlaceDeposit
      )
    },
    acceptAccommodation() {
      return (
        this.reservation.checkin_info &&
        (this.reservation.checkin_info.accept_portal_payments ||
          this.reservation.checkin_info.allow_manual_card_charge)
      )
    },
    shouldPayAccommodation() {
      const has_balance = this.reservation.balance_due > 0
      const is_local = this.reservation.extra_data?.is_local
      const check_in_payment_threshold =
        this.reservation.checkin_info?.partial_reservation_days_threshold
      if (
        is_local &&
        has_balance &&
        this.acceptAccommodation &&
        !this.pre_check_in.unlock_portal_access
      ) {
        return true
      }
      const partialPaid = this.reservation.payment_items?.find(
        item => item.status === 'SUCCEEDED'
      )
      const closeCheckIn = moment(this.reservation.check_in).isBefore(
        moment().add(check_in_payment_threshold, 'day')
      )
      return (
        !this.pre_check_in.unlock_portal_access &&
        this.acceptAccommodation &&
        this.reservation.is_merchant &&
        !this.reservation.use_vcc &&
        this.reservation.balance_due > 0 &&
        this.reservation.payment_intents.find(
          intent =>
            intent.metadata.type === 'accommodation' &&
            (intent.status === 'requires_source' ||
              intent.status === 'requires_payment_method')
        ) &&
        (!partialPaid || (partialPaid && closeCheckIn))
      )
    },
  },
  methods: {
    formatDate(date, format, timezone) {
      if (timezone) {
        return moment(date).tz(timezone).format(format)
      }
      return moment(date).format(format)
    },
    formatReservationPeriod(reservation, format) {
      return `${this.formatDate(
        reservation.check_in,
        format
      )} - ${this.formatDate(reservation.check_out, format)} `
    },
    toCurrency(amount, config = { currency: null, maxDigits: 2 }) {
      if (!amount && amount !== 0) return null
      const currency =
        config.currency || get('config.currency', window) || 'USD'
      return new Intl.NumberFormat(`en-US`, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: config.maxDigits || 2,
      }).format(amount)
    },
    toDollar(amount) {
      return this.toCurrency(amount, 2, 'USD')
    },
    formatImageUrl(url) {
      return (url || '').replace('/upload/', '/upload/q_auto/f_auto/')
    },
    isVideo(src) {
      return (
        src &&
        (src.indexOf('.m4v') !== -1 ||
          src.indexOf('.avi') !== -1 ||
          src.indexOf('.mpg') !== -1 ||
          src.indexOf('.mp4') !== -1 ||
          src.indexOf('.mov') !== -1)
      )
    },
    hasAbility(ability) {
      const { user } = this.$store.state.user
      if (isEmpty(ability)) {
        return true
      }
      if (user && user.abilities) {
        if (user.abilities.includes('super-admin')) {
          return true
        }
        if (Array.isArray(ability)) {
          return ability.some(ab => user.abilities.includes(ab))
        }
        return user.abilities.includes(ability)
      } else {
        return false
      }
    },
  },
}

export default CommonFunctions

<template>
  <v-main class="secondary lighten-5" style="padding-top: 0">
    <div class="d-flex align-center justify-space-between px-3 py-2">
      <locale-changer-header
        class="mr-2 locale-changer"
      ></locale-changer-header>
      <img :src="reservation.logo_url" height="34" />
    </div>
    <div v-if="step === 1">
      <div class="text-center text-uppercase text-h5 font-weight-medium">
        {{ $t('Feedback') }}
      </div>
      <div class="text-subtitle-1 text-center py-2">
        {{ $t('Share your experience with us') }}
      </div>
    </div>
    <div v-if="dataFetched" class="" width="100%" rounded>
      <div v-if="step === 2" class="step-2 d-flex flex-column align-center">
        <div style="width: 100%; position: relative">
          <v-img
            :src="reservation.listing.container_picture"
            class="px-5"
            style="filter: brightness(50%)"
            :aspect-ratio="imgRatio"
            width="100%"
            alt=""
          />
          <div
            v-if="reservation.listing"
            class="white--text mb-4 font-weight-medium ml-2 ml-md-12"
            style="position: absolute; left: 0; bottom: 0"
          >
            <div class="text-h6 font-weight-bold">
              {{ reservation.listing.nickname }}
            </div>
            <div class="text-body-2 mt-1">
              {{ reservation.listing.city_name }} - {{ nightsCount }} nights
            </div>
            <div class="text-body-2 mt-1">
              {{
                formatDate(
                  reservation.check_in,
                  'DD MMM',
                  reservation.listing.timezone
                )
              }}-
              {{
                formatDate(
                  reservation.check_out,
                  'DD MMM YYYY',
                  reservation.listing.timezone
                )
              }}
            </div>
          </div>
        </div>
        <div v-if="isMidStay" class="w-full d-flex justify-center mt-md-8">
          <v-sheet width="700" max-width="100%" class="py-4 px-2" elevation="0">
            <div class="font-weight-bold mt-2 mx-md-4">
              How are you enjoying your stay so far?
            </div>
            <review-form-item :item="checkup" @update="updateCheckup" />
            <v-divider class="mb-4" />

            <p class="font-weight-bold text-left mx-md-4">
              {{ $t('In Your Words') }}:
            </p>
            <v-textarea
              v-model="midStaySurvey.feedback"
              class="my-2 mx-md-4"
              full-width
              :placeholder="$t('Feedback')"
              outlined
              hide-details
            />
          </v-sheet>
        </div>
        <v-sheet width="700" max-width="100%" class="ma-md-8" elevation="0">
          <div v-if="!isMidStay">
            <div class="text-h6 font-weight-bold pa-8 pl-2 pl-md-6">
              {{ $t('Leave your feedback') }}:
            </div>
            <v-divider />
            <div
              v-for="item in questions"
              :key="item.value"
              class="my-4 mx-2 mx-md-3"
            >
              <review-form-item :item="item" @update="updateApplication" />
              <v-divider />
            </div>
          </div>
          <v-divider />
          <div class="text-center ma-4 mb-12">
            <v-btn
              class="submit-btn w-full"
              elevation="0"
              :disabled="!canSubmit"
              @click="send"
            >
              {{ $t('Send my feedback') }}
            </v-btn>
          </div>
        </v-sheet>
      </div>
      <v-col v-if="step === 3" cols="auto" class="text-center">
        <div v-if="!surveyRate" class="text-center text-subtitle-1">
          {{ $t('We are processing your input') }}...
        </div>
        <v-progress-circular
          v-if="!surveyRate"
          :size="70"
          :width="7"
          color="info"
          indeterminate
        />
        <v-container v-else>
          <v-card elevation="0" class="pa-4" style="margin-top: 10vh">
            <v-icon class="mb-10" size="80">$check</v-icon>
            <h1 class="font-weight-bold text-h6 text-sm-h4 mb-4 mb-sm-6">
              {{ $t('Thank you for your review') }}
            </h1>
            <div v-if="isMidStay" class="text-subtitle-2 pa-3">
              <div>{{ $t('We appreciate your time') }}</div>
              <div>{{ $t('Enjoy the rest of your stay') }}</div>
            </div>
            <div
              v-if="!isMidStay"
              class="mb-6 mb-sm-8 text-caption text-md-subtitle-1"
            >
              <div v-if="surveyRate >= goodReviewThreshold" class="text-center">
                <div class="font-weight-medium pb-3">
                  {{
                    $t("We're so happy to hear that you enjoyed your stay at")
                  }}
                  <b>{{ reservation.listing.nickname }}</b>
                  <br />
                  {{
                    $t(
                      "We'd like to ask you a favour. If you have the time we'd love it if you would post a review on"
                    )
                  }}
                  <span v-if="reservation.basic_company_info.links.google"
                    >Google (<a
                      target="_blank"
                      :href="
                        changeToHttps(
                          reservation.basic_company_info.links.google
                        )
                      "
                      >{{ $t('click here') }}</a
                    >
                    ),
                  </span>
                  <span v-if="reservation.basic_company_info.links.facebook"
                    >Facebook (<a
                      target="_blank"
                      :href="
                        changeToHttps(
                          reservation.basic_company_info.links.facebook
                        )
                      "
                      >{{ $t('click here') }}</a
                    >
                    )</span
                  >
                  <br />
                  {{
                    $t(
                      'Platform reviews are invaluable to us and other future guests.'
                    )
                  }}
                  <span v-if="reservation.source === 'Airbnb'">
                    (<a
                      target="_blank"
                      href="https://www.airbnb.com/trips/v1"
                      class="error--text text--lighten-3"
                      >{{ $t('Review on') }} Airbnb</a
                    >)
                  </span>
                </div>
              </div>
              <div v-if="surveyRate <= badReviewThreshold" class="mb-6 mb-sm-8">
                {{ $t("We're very sorry that your experience at") }}
                <b>{{ ' ' + reservation.listing.nickname + ' ' }}</b>
                {{
                  $t(
                    'did not meet your expectations and was not the best it could have been.'
                  )
                }}<br />{{
                  $t(
                    'We aim to provide the best possible experience to all of our guests.'
                  )
                }}<br />{{
                  $t(
                    'We will look into what went wrong with your stay and you can expect to hear from us soon.'
                  )
                }}
                <br />{{ $t('Sincerely,') }} <br />{{
                  $t('Team') + ` ${reservation.basic_company_info.company_name}`
                }}
              </div>
              <v-btn
                v-if="reservation.tenant_id === 1"
                class="mt-2"
                color="grey lighten-3"
                elevation="0"
                width="200"
                large
                @click="$router.replace('/tourist')"
                >{{ $t('Done') }}
              </v-btn>
            </div>
          </v-card>
        </v-container>
      </v-col>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ReviewFormItem from 'components/review/review-form-item'
import moment from 'moment'
import CommonFunctions from 'components/mixins/common-functions'
import get from 'lodash/fp/get'
import localeChangerHeader from 'components/locale-changer-header.vue'

export default {
  name: 'Review',
  components: { ReviewFormItem, localeChangerHeader },
  mixins: [CommonFunctions],
  data() {
    return {
      step: 2,
      hideImageStep: 3,
      badReviewThreshold: 7.5,
      goodReviewThreshold: 9,
      ticket: null,
      surveyRate: null,
      survey: {
        cleaning: null,
        communication: null,
        location: null,
        accuracy: null,
        check_in: null,
        value: null,
        feedback: '',
      },
      midStaySurvey: {
        rate: null,
        feedback: null,
      },
      checkup: {
        text: '',
        value: 'rate',
      },
      questions: [
        { text: this.$t('Cleaning'), value: 'cleaning' },
        {
          text: this.$t('Communication'),
          value: this.$t('communication'),
        },
        { text: this.$t('Location'), value: 'location' },
        { text: this.$t('Accuracy'), value: 'accuracy' },
        { text: this.$t('Check-in'), value: 'check_in' },
        { text: this.$t('Value'), value: 'value' },
        { text: this.$t('How can we improve? (optional)'), value: 'feedback' },
      ],
      dataFetched: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.tourist.reservationBasicInfo,
    }),
    isMidStay() {
      return moment().isBetween(
        this.reservation.check_in,
        this.reservation.check_out,
        'day',
        '[)'
      )
    },
    nightsCount() {
      const { check_in, check_out } = this.reservation
      return moment(check_out)
        .startOf('day')
        .diff(moment(check_in).startOf('day'), 'days', true)
    },
    logo() {
      return this.companyLogo || get('listing.public_logo', this.reservation)
    },
    imgRatio() {
      return (window.screen.width / window.screen.height) * 3.5
    },
    links() {
      let links = []
      if (this.reservation.source === 'Airbnb') {
        links.push({
          icon: '$airbnb',
          url: 'https://www.airbnb.com/trips/v1',
        })
      }
      return links
    },
    canSubmit() {
      return (
        Boolean(
          this.survey.accuracy &&
            this.survey.cleaning &&
            this.survey.check_in &&
            this.survey.communication &&
            this.survey.location &&
            this.survey.value
        ) || Boolean(this.midStaySurvey.rate)
      )
    },
  },
  async mounted() {
    await this.getReservationBasicInfo(this.$route.params.id)
    const filledSurvey = this.reservation.reviews.find(
      r => r.source === 'survey'
    )
    const filledMidSurvey = this.reservation.reviews.find(
      r => r.source === 'checkup'
    )

    if (filledSurvey) {
      this.surveyRate = filledSurvey.avg_rate
      this.step = 3
    }
    if (filledMidSurvey && this.isMidStay) {
      this.step = 3
      this.surveyRate = filledMidSurvey.avg_rate
      if (filledMidSurvey.listing_task) {
        this.ticket = `/tourist-lt/${filledMidSurvey.listing_task.public_id}`
      }
    }
    this.dataFetched = true
  },
  methods: {
    ...mapActions(['getReservationBasicInfo', 'createReservationSurvey']),
    updateApplication(item) {
      this.survey[item.field] = item.value
    },
    changeToHttps(src) {
      if (!src.includes('https')) {
        return 'https://' + src
      } else {
        return src
      }
    },
    updateCheckup(item) {
      this.midStaySurvey[item.field] = item.value * 2
    },
    async send() {
      this.step++
      const data = await this.createReservationSurvey({
        confirmation_code: this.$route.params.id,
        survey: this.isMidStay ? this.midStaySurvey : this.survey,
      })
      this.surveyRate = data.avg
      this.ticket = data.link
    },
  },
}
</script>

<style scoped>
.submit-btn {
  background-color: #25282f !important;
  color: #fff;
  text-transform: capitalize;
}
.locale-changer {
  position: absolute;
  right: 0;
  z-index: 1;
}
</style>
